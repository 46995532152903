import React from 'react';

/**
 * The IFrame containing the running CBA runtime.
 */
export default function RuntimeFrame(props: {runtimeUrl: string } ) : JSX.Element {

  return (
    <div className='RuntimeFrame'>
      <iframe
        data-cy="RuntimeIFrame"
        style={
          { 
            width: "100%",
            height: "calc(100% - 4px)",
            border: 0,
            boxSizing: "border-box",
            padding: "2px 0"
          }
        }
        title='RuntimeFrame'
        src={`${props.runtimeUrl}?eventTargetWindow=parent`}
      />
    </div>
  )
}