import zlib from 'zlib';
import { markServerInternalError } from './TextUtils';
import { SessionId } from '../auth/Authenticator';

/**
 * Methods that call the service API of our assessment server.
 */

// ----------------- public API --------------------------------------------------------------

/**
 * Call the validateStudent method.
 * 
 * This returns true if an assessment for the student contained in the authentication token 
 * is available. Otherwise it returns false.
 */
export function callValidateStudent(urlRoot: string, authToken: string) : Promise<boolean> {
  return fetchGetNoEmptyResult(urlRoot, 'validateStudent', authToken)
    .then(
      (data) => {
      if (typeof data === 'boolean') {
        return data;
      }
      else {
        throw new Error(`Der Server hat auf deine Anmeldung mit einer ungültigen Struktur geantwortet: ${data}`);
      }
    },
    (error) => {
      throw new Error(buildStandardErrorMessage('Wir können deine Anmeldung nicht prüfen.', error.message, authToken));
    });
}


/**
 * Call the getTaskList method.
 * 
 * This returns the lists of tasks and items that comprise the assessment.
 */
export function callGetTaskList(urlRoot: string, authToken: string) : Promise<AssessmentConfiguration> {
  return fetchGetNoEmptyResult(urlRoot, 'getTaskList', authToken)
    .then(
      (data) => {
      if (isAssessmentConfiguration(data)) {
        return data;
      }
      else {
        throw new Error(`Der Server hat einen ungültigen Abfolgeplan geschickt: ${JSON.stringify(data)}`);
      }
    },
    (error) => {
      throw new Error(buildStandardErrorMessage('Wir können den Abfolgeplan nicht runterladen.', error.message, authToken));
    });
}

/**
 * The list of tasks to execute during the assessment and the list of items required for the assessment.
 */
export interface AssessmentConfiguration {
  cbaRuntimeUrl: string,
  tasks: TaskIdentification[],
  items: ItemAccessInfo[]
};

function isAssessmentConfiguration(candidate: any) : candidate is AssessmentConfiguration {
  try {
    return typeof candidate.cbaRuntimeUrl === 'string'
      && Array.isArray(candidate.tasks) && candidate.tasks.every(isTaskIdentification)
      && Array.isArray(candidate.items) && candidate.items.every(isItemAccessInfo)
  }
  catch(error) {
    return false;
  }
}

/**
 * The identification of a single task.
 */
export interface TaskIdentification {
  itemName: string,
  task: string,
  scope: string
}

function isTaskIdentification(candidate: any) : candidate is TaskIdentification {  
  try {
    return (
      typeof candidate.itemName === 'string' 
      && typeof candidate.task === 'string' 
      && typeof candidate.scope === 'string' 
    );
  }
  catch(error) {
    return false;
  }
}

/**
 * Retrieval meta-data for a single item.
 */
 export interface ItemAccessInfo {
  itemName: string,
  configUrl: string,
  resourceUrl: string, 
  externalResourceUrl: string
}

function isItemAccessInfo(candidate: any) : candidate is ItemAccessInfo {  
  try {
    return (
      typeof candidate.itemName === 'string' 
      && typeof candidate.configUrl === 'string' 
      && typeof candidate.resourceUrl === 'string' 
      && typeof candidate.externalResourceUrl === 'string' 
    );
  }
  catch(error) {
    return false;
  }
}

/**
 * Call the getSnapshot method.
 * 
 * This returns the last assessment snapshot for the student available on the management server.
 */
 export function callGetSnapshot(urlRoot: string, authToken: string) : Promise<AssessmentSnapshot | undefined> {
  return fetchGet(urlRoot, 'getSnapshot', authToken)
    .then(
      (data) => {
      if (data === null)  {
        return undefined;
      }
      if (!isAssessmentSnapshot(data)) {
        throw new Error(`Wir können nicht prüfen, ob Du die Aufgabe schon einmal begonnen hattest, weil der Server mit einer ungültigen Struktur antwortet: ${JSON.stringify(data)}`);
      }
      return data;
    },
    (error) => {
      throw new Error(buildStandardErrorMessage('Wir können nicht prüfen, ob Du die Aufgabe schon einmal begonnen hattest.', error.message, authToken));
    });
}


/**
 * An assessment snapshot with its creation time.
 */
export interface AssessmentSnapshot {
  runtimeTasksState: unknown,
  taskSequencerState: TaskSequencerState,
  creationTime: number, 
  controllerVersion: string,
  task?: string, 
  item?: string, 
  scope?: string
};

export function isAssessmentSnapshot(candidate: any) : candidate is AssessmentSnapshot {
  try {
    return (
        candidate.runtimeTasksState && typeof candidate.runtimeTasksState === 'object'
      && candidate.taskSequencerState && isTaskSequencerState(candidate.taskSequencerState) 
      && typeof candidate.creationTime === 'number'
      && typeof candidate.controllerVersion === 'string'
      && (candidate.task === undefined || typeof candidate.task === 'string')
      && (candidate.item === undefined || typeof candidate.item === 'string')
      && (candidate.scope === undefined || typeof candidate.scope === 'string')
    )
  } 
  catch(error) {
    return false;
  }
}

/**
 * The state of the task sequencer save in an assessment snapshot.
 */
export interface TaskSequencerState {
  currentTaskIndex: number | 'not set'
}

function isTaskSequencerState(candidate: any) : candidate is TaskSequencerState {
  return (typeof candidate.currentTaskIndex === 'number' || candidate.currentTaskIndex === 'not set');  
}


/**
 * Call the addStudentTraceData method.
 * 
 * This saves the given trace data on the management server.
 */
export function callAddStudentTraceData(urlRoot: string, authToken: string, traceData: TraceLogMessage[], additionalQuotes: boolean) : Promise<void> { 
  return fetchPost(urlRoot, 'addStudentTraceData', authToken, traceData, undefined, false, additionalQuotes)
  .catch((error) => { 
    if (error.message === 'failure-status-409') {
      throw new Error(`Wir können deine Bearbeitungsschritte nicht hochladen. Du hast diese Aufgabe schon einmal abgeschlossen und kannst sie nicht weiter bearbeiten. [Token:]${authToken}`)
    }
    throw new Error(buildStandardErrorMessage('Wir können deine Bearbeitungsfortschritte nicht hochladen.', error.message, authToken));
  });

}

/**
 * Call the supplementStudentTraceData method.
 * 
 * This saves the given trace data on the management server with an explicit studentId/runId/sid.
 * The server will accept this call even if the transmission-done signal was already given for the assessment session of the specified studentId/runId/sid.
 */
export function callSupplementStudentTraceData(urlRoot: string, authToken: string, sessionId: SessionId, traceData: TraceLogMessage[], additionalQuotes: boolean) : Promise<void> {  
  return fetchPost(urlRoot, 'supplementStudentTraceData', authToken, traceData, sessionId, false, additionalQuotes)
  .catch((error) => { 
    throw new Error(buildStandardErrorMessage('Wir können einen alten Bearbeitungsstand nicht hochladen.', error.message, authToken));
  });

}

/**
 * A bunch of trace log entries sent with meta-data by the CBA runtime as a single trace log message.
 */
export interface TraceLogMessage {
  metaData: {
    userId?: string,
    sessionId: string,
    loginTimestamp?: string,
    sendTimestamp: string,
    cbaVers: string,
  },
  logEntriesList: TraceLogEntry[]
}

export function isTraceLogMessage(candidate: any) : candidate is TraceLogMessage {
  try {
    return (
      candidate.metaData && typeof candidate.metaData === 'object'
      && (candidate.metaData.userId === undefined || typeof candidate.metaData.userId === 'string')
      && typeof candidate.metaData.sessionId === 'string'
      && (candidate.metaData.loginTimestamp === undefined || typeof candidate.metaData.loginTimestamp === 'string')
      && typeof candidate.metaData.sendTimestamp === 'string'
      && typeof candidate.metaData.cbaVers === 'string'
      && Array.isArray(candidate.logEntriesList) && candidate.logEntriesList.every(isTraceLogEntry)   
    )
  } 
  catch(error) {
    return false;
  }
}


/**
 * A single trace log entry.
 */
export interface TraceLogEntry {
  entryId: number,
  timestamp:string,
  type: string,
  details: unknown
};

function isTraceLogEntry(candidate: any) : candidate is TraceLogEntry {
  try {
    return (
        typeof candidate.entryId === 'string'
      && typeof candidate.timestamp === 'string'
      && typeof candidate.type === 'string'
    )
  } 
  catch(error) {
    return false;
  }
}


/**
 * Call the setSnapshot method.
 * 
 * This saves the given assessment snapshot on the management server.
 */
export function callSetSnapshot(urlRoot: string, authToken: string, snapshot: AssessmentSnapshot, additionalQuotes: boolean) : Promise<void> {  
  return fetchPost(urlRoot, 'setSnapshot', authToken, snapshot, undefined, false, additionalQuotes)
  .catch((error) => { 
    throw new Error(buildStandardErrorMessage('Wir können deinen Bearbeitungsstand nicht hochladen.',error.message, authToken));
  });
}

/**
 * Call the addScoring method.
 * 
 * This saves the given scoring result on the management server.
 */
 export function callAddScoring(urlRoot: string, authToken: string, scoring: ScoringResult, additionalQuotes: boolean) : Promise<void> {
  return fetchPost(urlRoot, 'addScoring', authToken, scoring, undefined, false, additionalQuotes)
  .catch((error) => { 
    throw new Error(buildStandardErrorMessage('Wir können deine Ergebnisse nicht hochladen.',error.message, authToken));
  });
}

/**
 * A scoring result with its creation time.
 */
 export interface ScoringResult {
  runtimeScoringResult: unknown,
  creationTime: string, 
  controllerVersion: string,
  task?: string, 
  item?: string, 
  scope?: string
};


/**
 * Call the signalAssessmentComplete method.
 * 
 * This tells the management server that the assessment for the student is complete.
 */
export function callSignalAssessmentComplete(urlRoot: string, authToken: string) : Promise<void> {  
  return fetchPost(urlRoot, 'signalAssessmentComplete', authToken, undefined, undefined, false, false)
  .catch((error) => { 
    throw new Error(buildStandardErrorMessage('Wir können das Ende der Bearbeitung nicht signalisieren.', error.message, authToken));
  });
}

/**
 * Call the signalAssessmentTransmissionComplete method.
 * 
 * This tells the management server that the transmissions for the assessment for the student is complete.
 */
 export function callSignalAssessmentTransmissionComplete(urlRoot: string, authToken: string) : Promise<void> {  
  return fetchPost(urlRoot, 'signalAssessmentTransmissionComplete', authToken, undefined, undefined, false, false)
  .catch((error) => { 
    throw new Error(buildStandardErrorMessage('Wir können das Ende der Ergebnisrückmeldungen nicht signalisieren.', error.message, authToken));
  });
}

/**
 * Call a GET method on the server API that is guaranteed to return a result for success code 200.
 */
function fetchGetNoEmptyResult(urlRoot: string, callName: string, authToken: string) : Promise<unknown> {
  return fetchGet(urlRoot, callName, authToken).then((result) => {
    if (result === null) {
      throw new Error(`empty`)
    }
    return result;
  })
}

/**
 * Call a GET method on the server API.
 */
function fetchGet(urlRoot: string, callName: string, authToken: string) : Promise<null | unknown> {
  return fetch(`${urlRoot}/${callName}`, { 
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  })
    .then((response) => {
      return checkResponseStatus(response, callName, authToken) === 'no-result' ? null : response.json();
    })
}

/**
 * Call a POST method on the server API.
 */
function fetchPost(urlRoot: string, callName: string, authToken: string, data: unknown | undefined, sessionId: SessionId | undefined, compress: boolean, additionalQuotes: boolean) : Promise<void> {
  const preparedData : string | undefined = stringifyAndCompressIfRequired(data, compress, additionalQuotes);
  const idsAppendix = sessionId === undefined ? '' : `/${sessionId.studentId}/${sessionId.runId}/${sessionId.sid}`;
  return fetch(`${urlRoot}/${callName}${idsAppendix}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`
      // TODO: In compressed case: should we set the Content-Encoding header or switch Content-Type to application/zip?
    },
    body: preparedData
  })
    .then((response) => {
      checkResponseStatus(response, callName, authToken);
    })
}

function stringifyAndCompressIfRequired(data: unknown | undefined, compress : boolean, additionalQuotes : boolean) : string | undefined {
  if (data === undefined) return undefined;
  const stringified = JSON.stringify(data);
  // The server implemented by SoftwareDriven expects some JSON data as plain strings. -> Switch on/off additional stringify per parameter:
  const payload = additionalQuotes ? JSON.stringify(stringified) : stringified;
  return compress ? zlib.gzipSync(payload).toString('base64') : payload;
}


/**
 * Check the status code in the fetch response and throw an Error if it is not 200.
 */
function checkResponseStatus(response: Response, callName: string, authToken: string) : 'success' | 'no-result' {
  switch (response.status) {
    case 200: return 'success';
    case 204: return 'no-result';
    default: throw new Error(`failure-status-${response.status.toString(10)}`);
  }
}

/**
 * Build a user friendly sentence in German that describes the given response status coming in from the server.
 * 
 * @param status The server response status triggering the message.
 * @param authToken The authentication token that we should mention in the message.
 * @returns The German message text describing the server's response.
 */
function buildStandardErrorMessage(prefix: string, status: string, authToken: string) : string {
  if (status.startsWith('failure-status-')) {
    const failureStatusString = status.substring(15);
    switch (failureStatusString) {
      case 'empty': return `${prefix} Der Server hat ein leeres Ergebnis geschickt. [Token:]${authToken}`; 
      case '401': return `${prefix} Der Server hat blockiert (401). [Token:]${authToken}`;
      case '403': return `${prefix} Der Server hat die Bearbeitung abgelehnt (403). [Token:]${authToken}`;
      case '409': return `${prefix} Der Server hat einen Konflikt bemerkt (409). [Token:]${authToken}`;
      case '500': return markServerInternalError(`${prefix} Der Server hat einen internen Fehler gemeldet (500). [Token:]${authToken}`, true);
      default: return markServerInternalError(`${prefix} Der Server hat mit einem unerwarteten Status geantwortet: ${failureStatusString} [Token:]${authToken}`, failureStatusString.startsWith('5'));
    }  
  } else {
    return `${prefix} Eine Anfrage beim Server ist fehlgeschlagen: ${status} [Token:]${authToken}`
  }
}
