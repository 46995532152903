import React, { useContext } from 'react';
import { SingleInstanceRunner, SingleInstanceRunnerProps } from '../controller/SingleInstanceRunner';
import { callGetTaskList, callValidateStudent } from '../utils/ApiCalls';
import { AuthContextProps } from 'oidc-react';
import { ItemSize } from '../utils/FileDownload';
import LocalDatabase from '../database/LocalDatabase';
import BufferedTransmitter from '../controller/BufferedTransmitter';
import SnapshotsController from '../controller/SnapshotsController';
import { Auth } from '../authmock/Auth';
import { LoggerContext } from '../utils/Logger';
import { getMessageSystemDownMain, getMessageSystemDownSecondary, isServerInternalError} from '../utils/TextUtils';


/**
 * This level cooperates with the OpenId Connect authentication library to obtain an authenticated and validated user.
 * 
 * It runs a "retry-it" loop of error message displays until we are logged in as validated user.
 * It checks maco specific validation on the server via the "validateStudent" call. 
 * 
 */
export function LoginLoop(props: {
  itemSize: ItemSize, 
  localDatabase: LocalDatabase, 
  bufferedTransmitter: BufferedTransmitter, 
  snapshotsController: SnapshotsController, 
  serverLoginUrl: string,
  auth: AuthContextProps | Auth,
  signOut: () => void
}) : JSX.Element 
{
  const log = useContext(LoggerContext);

  const {auth} = props;
  log.log(`Login loops on URL:${window.location.href} at history length:${window.history.length} with loading:${auth.isLoading}` 
    + `, studentId:${auth.userData?.profile?.studentId}, runId:${auth.userData?.profile?.runId}, sid:${auth.userData?.profile?.sid}` 
    + `, access_token (up to 10 characters):${auth.userData?.access_token?.substring(0, 10)}.`);

  const [lastAuthenticatedToken, setLastAuthenticatedToken] = React.useState('' as string)
  const [lastStudentId, setLastStudentId] = React.useState('' as string)
  const [lastRunId, setLastRunId] = React.useState('' as string)
  const [lastSid, setLastSid] = React.useState('' as string)
  const [serverValidationResult, setServerValidationResult] = React.useState({ status: 'none', message: '', runtimeUrl: undefined} as ServerValidationResult)


  if (auth.isLoading) {
    return showMessageAndResetUserData('Deine Anmeldung beim Server läuft. Bitte warte einen Augenblick...', false);
  }


  window.history.replaceState({ comment: 'drop session data from URL' }, 'not used title', getStrippedUrl());

  if (auth.userData === undefined || auth.userData === null || auth.userData.access_token === '' 
    || auth.userData.profile.studentId === '' || auth.userData.profile.runId === '' || auth.userData.profile.sid === '') {
    return showMessageAndResetUserData('Die Aufgabe wurde unterbrochen oder war zu Ende. Willst Du weiter machen?', true);
  }


  // TODO: reconsider the check for user changes when hopping from tab to tab with OpenID Connect.

  const newToken = auth.userData.access_token;
  const newStudentId = auth.userData.profile.studentId as string;
  const newRunId = auth.userData.profile.runId as string;
  const newSid = auth.userData.profile.sid as string;
  if (lastAuthenticatedToken !== newToken) {
    setLastAuthenticatedToken(newToken);
    // If authenticated user changed: trigger new server side validation, capture result in our server validation status:
    if (lastStudentId !== newStudentId || lastRunId !== newRunId || lastSid !== newSid) {
      setLastStudentId(newStudentId);
      setLastRunId(newRunId);
      setLastSid(newSid);
      setServerValidationResult({status: 'none', message: '', runtimeUrl: undefined});
      callValidateStudent(props.serverLoginUrl, newToken)
      .then(isValid => {
        if (!isValid) {
          setServerValidationResult( {status: 'invalid', message: '', runtimeUrl: undefined})  
        } else {
          callGetTaskList(props.serverLoginUrl, newToken)
          .then(taskList => {
            setServerValidationResult( {status: 'valid', message: '', runtimeUrl: taskList.cbaRuntimeUrl});
          })
          .catch(reason => {
            setServerValidationResult( {status: isServerInternalError(reason.message) ? 'system-down' : 'error-tasklist', message: reason.message, runtimeUrl: undefined});
          })
        }
      })
      .catch(reason => {
        setServerValidationResult( {status: isServerInternalError(reason.message) ? 'system-down' : 'error-validate', message: reason.message, runtimeUrl: undefined});
      });
    }
  }


  // Server side validation status determines our display:
  switch (serverValidationResult.status) {
    case 'valid':
      if (serverValidationResult.runtimeUrl === undefined || serverValidationResult.runtimeUrl.length === 0) {
         return buildMessageDisplay(`Der Abfolgeplan vom Server enthält keine gültige Ablaufversion.`, true, 'Neuer Versuch');
      } else {
        return showAssessment(serverValidationResult.runtimeUrl);
      }
    case 'invalid':
      return buildMessageDisplay('Der Aufgabenschlüssel ist ungültig oder die Aufgabe war schon zu Ende.', true, 'Neuer Versuch');
    case 'error-validate':
      return buildMessageDisplay(`Die Prüfung beim Server ist gescheitert: ${serverValidationResult.message}`, true, 'Neuer Versuch');
    case 'error-tasklist':
       return buildMessageDisplay(`Der Abruf des Abfolgeplans vom Server ist gescheitert: ${serverValidationResult.message}`, true, 'Neuer Versuch');
     case 'system-down':
        return buildMessageDisplay(`${getMessageSystemDownMain()} ${getMessageSystemDownSecondary(true)}`, true, 'Neuer Versuch');
    case 'none':
      return buildMessageDisplay('Die Prüfung beim Server läuft. Bitte warte einen Augenblick...', false, '');
    default: {
        const _exhaustiveCheck: never = serverValidationResult.status;
        return _exhaustiveCheck;
    }
  }

  function showAssessment(runtimeUrl : string) : JSX.Element {
    console.log(`showing the runtime`);
    const runnerProps : SingleInstanceRunnerProps = {
      runtimeUrl,
      ...props
    }
    return (
        <SingleInstanceRunner { ... runnerProps } />
    );  
  }

  function showMessageAndResetUserData(message: string, withSignOutButton: boolean) : JSX.Element {
      if (lastAuthenticatedToken !== '') {
        setLastAuthenticatedToken('');
      }
      if (lastStudentId !== '') {
        setLastStudentId('');
      }
      if (lastRunId !== '') {
        setLastRunId('');
      }
      if (lastSid !== '') {
        setLastSid('');
      }

      return buildMessageDisplay(message, withSignOutButton, 'Weiter machen');
    }
  
  function buildMessageDisplay(message: string, withSignOutButton: boolean, buttonLabel: string) : JSX.Element {
    console.log(`showing a message: ${message}`);
    if (withSignOutButton) {
      return (
        <div>
          <div>{message}</div>      
          <div>
          <button onClick={() => props.signOut()}>{buttonLabel}</button>
          </div>
        </div>);
    } else {
      return (
        <div>
          <div>{message}</div>
        </div>);
    }
  }

  function getStrippedUrl() : string {
    return `${window.location.origin}${window.location.pathname}`; 
  }

}

interface ServerValidationResult {
  status: 'valid' | 'invalid' | 'error-validate' | 'error-tasklist' | 'system-down' | 'none',
  message: string,
  runtimeUrl: string | undefined;
}


