import React from 'react';
import { ItemSize } from '../utils/FileDownload';
import LocalDatabase from '../database/LocalDatabase';
import BufferedTransmitter from './BufferedTransmitter';
import SnapshotsController from './SnapshotsController';
import { RuntimeAndMaintenance } from './RuntimeAndMaintenance';

export interface SingleInstanceRunnerProps {
  runtimeUrl: string,
  itemSize: ItemSize,
  localDatabase: LocalDatabase,
  bufferedTransmitter: BufferedTransmitter,
  snapshotsController: SnapshotsController,
  signOut: () => void  
}

/**
 * This is the level that runs the competitor detection loop.
 * 
 * Usually we just show a single IFrame which contains the CBA runtime.
 * 
 * Once we detect another instance of our application running on our browser
 * we stop all loops run by our controllers and replace the CBA runtime frame by a simple message
 * and the option to log out.
 */
export function SingleInstanceRunner(props: SingleInstanceRunnerProps): JSX.Element {
  const { runtimeUrl, localDatabase, bufferedTransmitter, snapshotsController, signOut } = props;
  
  // The switch to block our instance if a competitor becomes active.
  const [blocked, setBlocked] = React.useState(false);

  // Did we initialize the competitor check loop already?
  const [loopInitialized, setLoopInitialized] = React.useState(false);

  // Check for competitors once per second.
  // NOTE: During development we run with React's strict-mode which will duplicate render calls internally.
  //       You usually don't see console logs from the additional rendering since React hides these.
  //       But React does not suppress the console logs from the duplicated timers.
  //       Duplicating the render step is not done in production mode. 
  //       For further details see https://stackoverflow.com/questions/65765897/settimeout-callback-called-twice-in-react-functional-component.
  if (!loopInitialized) {
    setLoopInitialized(true);
    competitorCheckLoop(localDatabase, (competitor: string) => {
      setBlocked(true);
      bufferedTransmitter.stopTransmissions();
      snapshotsController.stopDbTransmissions();
    }, 1000);
  }

  return (
    blocked
      ? <div>
        <h3>Du bist zu einem früher benutzten Tab zurückgesprungen.</h3>
        <h3>Vermeide Tab-Wechsel wenn möglich.</h3>
        <h3>Du kannst nicht parallel in mehreren Tabs arbeiten. Die anderen Tabs werden durch den Wechsel blockiert.</h3>
        <h3>Willst Du auf diesem Tab weiter machen?</h3>
        <button onClick={() => signOut()}>Hier weiter machen</button>
      </div>
      : <RuntimeAndMaintenance runtimeUrl={runtimeUrl} signOut={signOut} />
  );


  /**
   * Start the loop that checks for other instances of our app running in our browser.
   * 
   * Once the loop detects another instance it calls the competitorDetected method and stops looping. 
   */
  function competitorCheckLoop(localDatabase: LocalDatabase, competitorDetected: (competitor: string) => void, interval: number): void {
    if (localDatabase.isInitialized()) {
      localDatabase.checkConcurrentInstance()
        .then((competitor) => {
          if (competitor === undefined) {
            // run again soon (and do not deliver a result yet):
            window.setTimeout(() => { competitorCheckLoop(localDatabase, competitorDetected, interval) }, interval);
          } else {
            // deliver result (and do not run again):
            competitorDetected(competitor);
          }
        });
    }
    else {
      // run again soon (and do not deliver a result yet):
      window.setTimeout(() => { competitorCheckLoop(localDatabase, competitorDetected, interval) }, interval);
    }
  }

}


